import React from "react";
import { Link } from "react-router-dom";
import { WomanSVG } from "../assets/WomanSVG";
import { ManSVG } from "../assets/ManSVG";

const BillRow = ({ data }) => {
  return (
    <>
      <tr className="bg-white border-b">
        <th
          scope="row"
          className="px-6 py-4 font-font-semibold text-[#464E5F] whitespace-nowrap p-1 flex flex-row items-center"
        >
          {<ManSVG />}
          <div className="flex flex-col">{data?.studentId?.username}</div>
        </th>
        <td className="px-6 py-4 font-semibold text-[#464E5F]">
          {new Date(data.createdAt).toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </td>
        <td className="px-6 py-4 font-semibold text-[#464E5F]">
          <div className="flex flex-col">{data.amount}</div>
        </td>
        <td className="px-6 py-4">
          <div className="flex flex-col font-bold">{data.status}</div>
        </td>
        <td className="px-6 py-4">
          <div className="flex flex-col">
            <button
              className="p-2 bg-[#C9F7F5] text-[#1BC5BD] rounded-md"
              onClick={() => {
                console.log("mail functionality coming")
              }}
            >
              Raise Issue
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default BillRow;
