import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import logo from "../assets/logo.png";
import GlobalContext from "../context/GlobalContext";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
export default function CalendarHeader() {
  const { monthIndex, setMonthIndex,showMonthlyCalendar, setShowMonthlyCalendar } = useContext(GlobalContext);
  const [showDropDown, setShowDropDown] = useState(false);
  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }
  function handleReset() {
    setMonthIndex(
      monthIndex === dayjs().month()
        ? monthIndex + Math.random()
        : dayjs().month()
    );
  }

  function handleMonthlyView(){
    setShowMonthlyCalendar(!showMonthlyCalendar);
  }

  function handleSelect(){
    setShowMonthlyCalendar(!showMonthlyCalendar);
  }
  return (
    <span className="px-4 py-2 flex items-center">
      <span className="mr-2">
      <CalendarMonthIcon />
      </span>
      <h1 className="mr-10 text-xl text-gray-500 fond-bold">
        Your Appointments
      </h1>
      <button
        onClick={handleReset}
        className="border rounded py-2 px-4 mr-5"
      >
        Today
      </button>
      <button onClick={handlePrevMonth}>
        <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
        <ChevronLeftIcon />
        </span>
      </button>
      <button onClick={handleNextMonth}>
        <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
        <ChevronRightIcon />
        </span>
      </button>
      <h2 className="ml-4 text-xl text-gray-500 font-bold">
        {dayjs(new Date(dayjs().year(), monthIndex)).format(
          "MMMM YYYY"
        )}
      </h2>
      <select value={showMonthlyCalendar} onChange={handleSelect} className="border rounded py-2 px-4 mx-5">
        <option className="rounded py-2 px-4 mx-5 " value={true}>Monthly View</option>
        <option className="rounded py-2 px-4 mx-5 " value={false}>Weekly View</option>
      </select>
    </span>
  );
}
