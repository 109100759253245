import React, { useEffect, useState } from "react";
import * as pdfjs from "pdfjs-dist/legacy/build/pdf";
import PDFViewer from "./pdf/PDFViewer";
import annotationService from "./services/annotation-service";
import { API_URL } from "./services/annotation-service";
import Question from "./Questions/Question";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "./Client";
import { Button } from "react-bootstrap";

const PDFComponent = () => {
  const [annotations, setAnnotations] = useState();
  const pdf = useSelector((state) => state.pdfName);
  const dispatch = useDispatch();

  const [pdfUrl, setPdfUrl] = useState(localStorage.getItem("selected_pdf"));

  const [selectPdfType, setSelectPdfType] = useState("normal");

  const [updateElement, setUpdateElement] = useState(true);

  const selected_row = localStorage.getItem("selected_row")
    ? JSON.parse(localStorage.getItem("selected_row"))
    : {};

  useEffect(() => {
    const addQuestiontToReducer = async () => {
      const res = await Client.get(
        "/evalutaor/getquestionById/" +
          localStorage.getItem("quesId") +
          "/" +
          localStorage.getItem("selected_testId")
      );
      console.log("🚀 ~ addQuestiontToReducer ~ res:", res.data);
      dispatch({ type: "ADD_ALL_QUESTIONS", payload: [res?.data] });
    };

    addQuestiontToReducer();
    // let filename = localStorage.getItem("selected_pdf");
    // const arr_annotation = [];
    // const res =  annotationService.getAll(filename)
    // .then(res =>{
    //   for(let i=0;i<res.length;i++){
    //     let anno = JSON.parse(res[i].annotation);
    //     arr_annotation.push(anno);
    //     }
    //     return arr_annotation;
    //   }).then(res => setAnnotations(res));
  }, []);

  const handleSwitchAI = () => {
    setUpdateElement(false);
    if (selectPdfType == "normal") {
      setSelectPdfType("AI");
      setPdfUrl(selected_row?.reviewerupdatedfile?.[0]?.useranswerInfo);
    } else {
      setSelectPdfType("normal");
      setPdfUrl(selected_row?.useranswerInfo?.[0]?.useranswerInfo);
    }
  };

  useEffect(() => {
    if (updateElement) return false;

    setUpdateElement(true);
  }, [updateElement]);

  return (
    <>
      {selected_row?.status === "AI_CHECK" && (
        <div
          style={{
            cursor: "pointer",
          }}
        >
          {/* <p onClick={handleSwitchAI}>
          {" "}
          Switch to {selectPdfType !== "AI" ? "AI" : "normal"} Copy
        </p> */}
          <Button variant="primary" onClick={handleSwitchAI}>
            Switch to {selectPdfType !== "AI" ? "AI" : "normal"} Copy
          </Button>{" "}
        </div>
      )}
      <div className="flex flex-row" style={{}}>
        {selected_row?.aiReviewedFile?.[0]?.useranswerInfo && (
          <div
            style={{
              display: selectPdfType !== "AI" ? "none" : "",
            }}
          >
            {/* {updateElement && ( */}
            <PDFViewer
              style={{ width: "60%" }}
              mode="scrolling"
              config={{
                relationVocabulary: ["located_at", "observed_at"],
              }}
              url={selected_row?.aiReviewedFile?.[0]?.useranswerInfo}
              annotations={annotations}
              onCreateAnnotation={(a) => console.log(JSON.stringify(a))}
              onUpdateAnnotation={(a, b) => console.log(JSON.strinygify(a, b))}
              onDeleteAnnotation={(a) => console.log(JSON.stringify(a))}
              selected_row={selected_row}
              handleSwitchAI={handleSwitchAI}
            />
            {/* )} */}
          </div>
        )}
        <div
          style={{
            display: selectPdfType === "AI" ? "none" : "",
          }}
        >
          {/* {updateElement && ( */}
          <PDFViewer
            style={{ width: "60%" }}
            mode="scrolling"
            config={{
              relationVocabulary: ["located_at", "observed_at"],
            }}
            url={selected_row?.useranswerInfo?.[0]?.useranswerInfo}
            annotations={annotations}
            onCreateAnnotation={(a) => console.log(JSON.stringify(a))}
            onUpdateAnnotation={(a, b) => console.log(JSON.stringify(a, b))}
            onDeleteAnnotation={(a) => console.log(JSON.stringify(a))}
            selected_row={selected_row}
            handleSwitchAI={handleSwitchAI}
          />
          {/* )} */}
        </div>
        {pdf && (
          <div style={{ flexGrow: 1 }}>
            <Question selectPdfType={selectPdfType} />
          </div>
        )}
      </div>
    </>
  );
};

export default PDFComponent;
