import axios from "axios";
import { Client } from "../Client";



class FileService {

  getBills() {
    return Client
      .get('/evalutaorbill')
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

  getQuestions() {
    return Client
      .get('/evalutaorquestionlist')
      .then(response => {
        return response;
      })
      .catch(err => {
        console.log(err);
        return err;
      })
  }

}

export default new FileService();