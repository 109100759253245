import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || "https://65.2.25.127.nip.io/api/v1";

class AuthService {
  login(email, password) {
    return axios
      .post(REACT_APP_API_URL + "/elogin", {
        email_Id: email,
        password: password
      })
      .then(response => {
        if (response.status == 200) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("token", response.data.token);
        }
        return response;
      });
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("selected_pdf");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();