export const ManSVG = () => {
  return (
    <svg width="50" height="50" className="mr-2" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" rx="6" fill="#F3F6F9"/>
    <path d="M38.5024 47.6743L37.6295 40.6905C37.4977 39.6364 36.7723 38.7516 35.7646 38.4156L32.3985 37.2936C31.9362 37.1394 31.4292 37.2376 31.0727 37.5413L30.0811 37.2108C29.518 37.023 29.138 36.496 29.138 35.9023V33.678L32.1088 32.4401C33.6508 31.7978 34.6552 30.2912 34.6552 28.6207V27.931C35.7979 27.931 36.7241 27.0047 36.7241 25.862C36.7241 25.3466 36.5353 24.8756 36.2235 24.5133C36.8089 22.3825 38.4543 15.6421 36.7241 12.7586L37.4138 10C37.4138 10 30.1724 10.1724 26.3793 11.3793L27.7586 10C27.7586 10 18.0604 10 16.0351 13.4477L16.0345 13.4483C16.0345 13.4483 12.5863 13.4196 12.5863 17.5862C12.5863 19.1112 13.4369 22.9191 13.802 24.484C13.4751 24.8499 13.2759 25.3326 13.2759 25.862C13.2759 27.0047 14.2022 27.931 15.3449 27.931V28.6207C15.3449 30.2911 16.3493 31.7977 17.8913 32.4403L20.8621 33.6781V35.9024C20.8621 36.4961 20.4822 37.0231 19.919 37.2109L18.9275 37.5414C18.571 37.2377 18.0639 37.1395 17.6017 37.2937L14.2356 38.4157C13.2277 38.7517 12.5024 39.6365 12.3707 40.6906L11.4977 47.6744C11.3433 48.9092 12.3062 49.9999 13.5507 49.9999H13.5701H36.43H36.4494C37.6939 49.9999 38.6568 48.9092 38.5024 47.6743Z" fill="#FFD7A3"/>
    <path d="M29.1379 33.6781L20.862 33.6782V35.9024C20.862 36.3989 20.5874 36.8361 20.1709 37.0799C25.9549 38.0922 29.1379 33.6781 29.1379 33.6781Z" fill="#FDC88E"/>
    <path d="M13.9657 25.1724C13.9657 25.1724 12.5864 19.5281 12.5864 17.5862C12.5864 13.4196 16.0347 13.4483 16.0347 13.4483L16.0353 13.4477C18.0606 10 27.7588 10 27.7588 10L26.3795 11.3793C30.1726 10.1724 37.414 10 37.414 10L36.7243 12.7586C38.7933 16.2069 36.0346 25.1724 36.0346 25.1724H13.9657Z" fill="#734A3E"/>
    <path d="M27.7588 10C27.7588 10 18.0606 10 16.0353 13.4477L16.0347 13.4483C16.0347 13.4483 12.5864 13.4196 12.5864 17.5862C12.5864 19.5281 13.9657 25.1724 13.9657 25.1724C13.9657 25.1724 28.3048 18.7644 37.414 10C37.414 10 30.1726 10.1724 26.3795 11.3793L27.7588 10Z" fill="#623F33"/>
    <path d="M34.6551 23.7932V22.4138C34.6551 20.5095 33.1113 18.9656 31.2069 18.9656H18.7931C16.8887 18.9656 15.3448 20.5095 15.3448 22.4138V23.7932C14.2022 23.7932 13.2759 24.7194 13.2759 25.8621C13.2759 27.0048 14.2022 27.9311 15.3448 27.9311V28.6207C15.3448 30.2912 16.3492 31.7978 17.8912 32.4404L22.3473 34.2971C23.1878 34.6474 24.0894 34.8276 24.9999 34.8276C25.9104 34.8276 26.8119 34.6473 27.6524 34.2971L32.1088 32.4403C33.6507 31.7979 34.6551 30.2912 34.6551 28.6207V27.9311C35.7978 27.9311 36.7241 27.0048 36.7241 25.8621C36.7241 24.7194 35.7978 23.7932 34.6551 23.7932Z" fill="#FFE1B2"/>
    <path d="M20.1726 26.5516C19.7933 26.5516 19.4829 26.2413 19.4829 25.862V25.1723C19.4829 24.793 19.7933 24.4827 20.1726 24.4827C20.5519 24.4827 20.8622 24.793 20.8622 25.1723V25.862C20.8622 26.2413 20.5519 26.5516 20.1726 26.5516Z" fill="#623F33"/>
    <path d="M29.8276 26.5516C29.4483 26.5516 29.1379 26.2413 29.1379 25.862V25.1723C29.1379 24.793 29.4483 24.4827 29.8276 24.4827C30.2069 24.4827 30.5172 24.793 30.5172 25.1723V25.862C30.5172 26.2413 30.2069 26.5516 29.8276 26.5516Z" fill="#623F33"/>
    <path d="M25.0001 31.3983C23.774 31.3983 22.6271 31.0966 21.8536 30.5703C21.5388 30.3561 21.4569 29.9271 21.6714 29.6122C21.8852 29.2978 22.3146 29.2159 22.6294 29.4301C23.1716 29.7988 24.0579 30.0191 25.0001 30.0191C25.9424 30.0191 26.8287 29.7988 27.3708 29.4301C27.6863 29.2153 28.1147 29.2974 28.3288 29.6122C28.5433 29.9271 28.4615 30.3561 28.1467 30.5703C27.3732 31.0966 26.2262 31.3983 25.0001 31.3983Z" fill="#E4B07B"/>
    <path d="M38.5024 47.6743L37.6295 40.6905C37.4978 39.6364 36.7723 38.7515 35.7646 38.4156L32.3985 37.2935C31.8123 37.0981 31.1528 37.3068 30.8217 37.8285C29.5991 39.7547 27.4504 41.0343 25 41.0343C22.5497 41.0343 20.401 39.7546 19.1785 37.8285C18.8474 37.3068 18.1879 37.0981 17.6017 37.2935L14.2356 38.4156C13.2277 38.7515 12.5024 39.6364 12.3707 40.6905L11.4977 47.6743C11.3433 48.9091 12.3063 49.9999 13.5507 49.9999H36.4495C37.6939 49.9999 38.6568 48.9091 38.5024 47.6743Z" fill="#00BEA4"/>
    <path d="M12.9456 39.3248C12.6394 39.7125 12.4344 40.1809 12.3707 40.6906L11.4977 47.6744C11.3433 48.9093 12.3063 50 13.5507 50H17.4138V45.5115C17.4138 44.8479 17.1747 44.2067 16.7403 43.7052L12.9456 39.3248Z" fill="#00AB9A"/>
    <path d="M37.0544 39.3248C37.3607 39.7125 37.5657 40.1809 37.6294 40.6906L38.5023 47.6744C38.6567 48.9093 37.6938 50 36.4493 50H32.5862V45.5115C32.5862 44.8479 32.8253 44.2067 33.2597 43.7052L37.0544 39.3248Z" fill="#00AB9A"/>
    <path d="M18.9652 31.0106C18.4064 30.4115 18.1035 29.6209 18.1035 28.8016V20.9861C20.2986 20.8215 27.2194 20.223 31.207 18.9656H18.7931C18.5557 18.9656 18.3258 18.9932 18.1035 19.0415V19.0351C16.5296 19.3546 15.3448 20.7457 15.3448 22.4138V23.7932C14.2022 23.7932 13.2759 24.7194 13.2759 25.8621C13.2759 27.0048 14.2022 27.9311 15.3448 27.9311V28.6207C15.3448 30.2912 16.3492 31.7979 17.8912 32.4404L22.3472 34.2971C22.5653 34.388 22.7879 34.4662 23.0134 34.5341C21.0441 33.0934 19.7332 31.834 18.9652 31.0106Z" fill="#FFD7A3"/>
    </svg>
  );
};