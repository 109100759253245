let initialState = {
  pdfName: "",
  current_page: 1,
  total_pages: 0,
  paintMode: false,
  paintColor: "",
  lineWidth: 5,
  eraseMode: false,
  paintToggle: false,
  historyIndex: 0,
  history: [],
  questions: [],
  feedback: {},
  canvasAllPage: {},
  annotations: [],
  marksheet: [],
  canvasWidth: 0,
  canvasHeight: 0,
};

// console.log("🚀 ~ file: reducer.js:20 ~ initialState.marksheet:", initialState.marksheet)

export default function reducer(currentState = initialState, action) {
  switch (action.type) {
    case "CHANGE_PAGE_NUM":
      return {
        ...currentState,
        current_page: action.payload,
      };
    case "SET_TOTAL_PAGES":
      return {
        ...currentState,
        total_pages: action.payload,
      };
    case "PAINT_MODE":
      return {
        ...currentState,
        paintMode: action.payload,
      };
    case "CHANGE_LINE_WIDTH":
      return {
        ...currentState,
        lineWidth: action.payload,
      };
    case "CHANGE_COLOR":
      return {
        ...currentState,
        paintColor: action.payload,
      };
    case "ERASE_MODE":
      return {
        ...currentState,
        eraseMode: action.payload,
      };
    case "PAINT_TOGGLE":
      return {
        ...currentState,
        paintToggle: action.payload,
      };
    case "HISTORY_INDEX_INCREMENT":
      return {
        ...currentState,
        historyIndex: currentState.historyIndex + 1,
      };
    case "HISTORY_INDEX_DECREMENT":
      return {
        ...currentState,
        historyIndex: currentState.historyIndex - 1,
      };
    case "ADD_TO_HISTORY":
      return {
        ...currentState,
        history: [...currentState.history, action.payload],
      };
    case "SET_HISTORY_INDEX":
      return {
        ...currentState,
        historyIndex: currentState.history.length - 1,
      };
    case "RESET_HISTORY_INDEX":
      return {
        ...currentState,
        historyIndex: 0,
        history: [],
      };
    case "PDF_SELECTED":
      return {
        ...currentState,
        pdfName: action.payload,
      };
    case "ADD_ALL_QUESTIONS":
      return {
        ...currentState,
        questions: [action.payload],
      };
    case "ADD_FEEDBACK":
      return {
        ...currentState,
        feedback: [action.payload],
      };
    case "ADD_CANVAS":
      return {
        ...currentState,
        canvasAllPage: {
          ...currentState.canvasAllPage,
          [action.payload.pageNum]: action.payload.image,
        },
      };
    case "ADD_ANNOTATIONS":
      return {
        ...currentState,
        annotations: [...currentState.annotations, action.payload],
      };
    case "DELETE_ANNOTATIONS":
      let newAnno = currentState.annotations.filter(
        (curr) => curr.id !== action.payload.id
      );
      return {
        ...currentState,
        annotations: newAnno,
      };
    case "ADD_MARKSHEET":
      // Check if an element with the same questionId exists in the marksheet array
      const existingElementIndex = currentState.marksheet.findIndex(
        (item) => item.questionId === action.payload.questionId
      );

      if (existingElementIndex !== -1) {
        // If an element with the same questionId exists, update it
        const updatedMarksheet = [...currentState.marksheet];
        updatedMarksheet[existingElementIndex] = action.payload;

        return {
          ...currentState,
          marksheet: updatedMarksheet,
        };
      } else {
        // If no element with the same questionId exists, add a new element
        return {
          ...currentState,
          marksheet: [...currentState.marksheet, action.payload],
        };
      }

    case "ADD_CANVAS_HEIGHT":
      return {
        ...currentState,
        canvasHeight: action.payload,
      };
    case "ADD_CANVAS_WIDTH":
      return {
        ...currentState,
        canvasWidth: action.payload,
      };
    case "RESET_CANVAS":
      return {
        ...currentState,
        canvasAllPage: {},
      };
    case "RESET_ANNOTATIONS":
      return {
        ...currentState,
        annotations: [],
      };
    case "RESET_QUESTIONS":
      return {
        ...currentState,
        questions: [],
      };
    case "RESET_FEEDBACK":
      return {
        ...currentState,
        feedback: {},
      };
    case "RESET_MARKSHEET":
      return {
        ...currentState,
        marksheet: [],
      };
    default:
      return currentState;
  }
}
