import React, { useContext } from "react";
import PlusSVG from "./PlusSVG";
import GlobalContext from "../context/GlobalContext";
import AddIcon from '@mui/icons-material/Add';
export default function CreateEventButton() {
  const { setShowEventModal } = useContext(GlobalContext);
  return (
    <button
      // onClick={() => setShowEventModal(true)}
      className="border p-2 rounded-full flex items-center shadow-md hover:shadow-2xl"
    >
      <span className="w-7 h-7">
            <AddIcon />
        </span>
      <span className="pl-3 pr-7"> Create</span>
    </button>
  );
}
