import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const FeedbackOption = ({ uuid, point, tooltipTitle, value, handleChange }) => {
  return (
    <FormControl
      id={uuid}
      name={uuid}
    >
      <Tooltip
        title={<div style={{ whiteSpace: "pre-line" }}>{tooltipTitle}</div>}
      >
        <FormLabel id={uuid}>{point}</FormLabel>
      </Tooltip>

      <RadioGroup
        row
        aria-labelledby={uuid}
        name={uuid}
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="1-3" control={<Radio />} label="1-3" />
        <FormControlLabel value="3-5" control={<Radio />} label="3-5" />
        <FormControlLabel value="6-8" control={<Radio />} label="6-8" />
        <FormControlLabel value="9-10"  control={<Radio />} label="9-10" />
      </RadioGroup>
    </FormControl>
  );
};

export default FeedbackOption;
