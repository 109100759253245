import React, { useState } from "react";
import "./MarkSheet.css";
import { useSelector, useDispatch } from "react-redux";
import MarkSheetRow from "./MarksheetRow";

const MarkSheet = () => {
  const [up, setUp] = useState(false);
  const handleChange = () => {
    setUp(!up);
  };
  const questions = useSelector((state) => state.questions);

  return (
    <div
      className={
        !up
          ? "backward absolute left-0 right-0 z-30"
          : "forward bottom-[-352px] absolute left-0 right-0 z-30"
      }
    >
      <button className={up ? "bg-[#6FCF97] up" : "up"} onClick={handleChange}>
        <div className="flex flex-row items-center">
          MarkSheet
          {up ? (
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.6653 6.0636L8.33195 11.3969L2.99862 6.0636"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.00757 11.6475L8.34974 6.32298L13.6742 11.6652"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </button>
      <div className={"left-0 right-0 z-30"}>
        <div className="relative overflow-x-auto bg-[#1c2537] border-[#1c2537] border-md h-[300px] overflow-y-scroll">
          <table className="w-full text-sm text-left text-[#454B54]  mx-10 my-6">
            <thead className="text-base text-[#8F97A3] uppercase bg-white border-t-s border-[#EAE2FF] sticky top-6">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Question No.
                </th>
                <th scope="col" className="px-6 py-3">
                  <input type="checkbox" onClick="return false" checked />
                </th>
                <th scope="col" className="px-6 py-3">
                  Attempted
                </th>
                <th scope="col" className="px-6 py-3">
                 Total Marks
                </th>
                <th scope="col" className="px-6 py-3">
                  Marks
                </th>
              </tr>
            </thead>
            <tbody>
              {questions.map((data, idx) => (
                <MarkSheetRow key={data && data[0].id} data={data} idx={idx} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MarkSheet;
