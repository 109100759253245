import React from 'react';
import PDFComponent from './PDFComponent';
import MenuC from './MenuC/MenuC';
import './App.css';
import { Outlet } from 'react-router-dom';

const App = () => {
  return (
    <>
      <MenuC />
      <div className='app' style={{ marginLeft: 60 }}>
        <Outlet />
      </div>
    </>

  )
}

export default App