import React, { useState } from "react";
import SubAccordion from "./SubAccordion";

const Accordion = ({ data, index }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>Question {index + 1}</div>
        <div>{isActive ? "-" : "+"}</div>
      </div>
      {isActive && (
        <div className="accordion-content">
          {data[0].paragraph && (
            <SubAccordion 
              data={data[0].paragraph} 
              title="Paragraph" 
            />
          )}
          {data[0].question_desc && (
            <SubAccordion 
              data={data[0].question_desc} 
              title="Question Description"
            />
          )}
          {data[0].notes && 
            <SubAccordion data={data[0].notes} title="Notes" 
            />
          }
          {data[0].questionmodelanswer && (
            <SubAccordion
              data={data[0].questionmodelanswer}
              title="Question Model Answer"
            />
          )}
          {data[0].question_KPI && (
            <SubAccordion 
              data={data[0].question_KPI} 
              title="Question KPI" 
            />
          )}
          {data[0].infographics && (
            <SubAccordion 
              data={data[0].infographics} 
              title="Infographics" 
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Accordion;
