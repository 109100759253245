import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import App from './App';
import Login from './Authentication/Login';
import MenuC from './MenuC/MenuC';
import PDFComponent from './PDFComponent';
import authServices from './services/auth-services';
import Main from './Evaluation/Main';
import MainCalendar from './Calendar/MainCalendar';
import Bills from './Bills/Bills';

function RouterC() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/login" element={<Login />} />
        <Route exact path="/" element={authServices.getCurrentUser() ? <App /> : <Navigate to="/login" replace={true} />} >
          <Route path="/" element={<Main />} />
          <Route path="bills" element={<Bills />} />
          <Route path='evaluate' element={<PDFComponent />} />
          <Route path='calendar' element={<MainCalendar />} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default RouterC