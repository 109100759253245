import React, {Component} from 'react';
import {DayPilot, DayPilotCalendar, DayPilotNavigator} from "@daypilot/daypilot-lite-react";
import "./WeeklyCalendar.css";
import Modal from 'react-modal';
import GlobalContext from '../context/GlobalContext';
import EventDetailModal from './EventDetailModal';
const styles = {
  wrap: {
    display: "flex",
    flex:"1"
  },
  main: {
    flexGrow: "1"
  }
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
  },
};


// const events = [
//   {
//     id: 1,
//     text: "Event 1",
//     start: "2023-03-07T10:30:00",
//     end: "2023-03-07T13:00:00",
//     meeting_link:"https://meet.google.com/vjo-fxer-rhx",
//     bubbleHtml: "Testing bubble HTML"
//   },
//   {
//     id: 2,
//     text: "Event 2",
//     start: "2023-03-08T09:30:00",
//     end: "2023-03-08T11:30:00",
//     backColor: "#6aa84f",
//     meeting_link:"https://meet.google.com/vjo-fxer-rhx",
//   },
//   {
//     id: 3,
//     text: "Event 3",
//     start: "2023-03-08T12:00:00",
//     end: "2023-03-08T15:00:00",
//     backColor: "#f1c232",
//     meeting_link:"https://meet.google.com/vjo-fxer-rhx",
//   },
//   {
//     id: 4,
//     text: "Event 4",
//     start: "2023-03-06T11:30:00",
//     end: "2023-03-06T14:30:00",
//     backColor: "#cc4125",
//     meeting_link:"https://meet.google.com/vjo-fxer-rhx",
//     description:"this is a description"
//   },
// ];


class WeeklyCalendar extends Component {

  static contextType = GlobalContext

  constructor(props) {
    super(props);
    this.calendarRef = React.createRef();
    this.state = {
      viewType: "Week",
      durationBarVisible: true,
      timeRangeSelectedHandling: "Enable",
      onTimeRangeSelected: async args => {
        const dp = this.calendar;
        const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
        dp.clearSelection();
        if (!modal.result) { return; }
        dp.events.add({
          start: args.start,
          end: args.end,
          id: DayPilot.guid(),
          text: modal.result
        });
        let currEvent = {
          start: args.start,
          end: args.end,
          id: DayPilot.guid(),
          text: modal.result,
          meeting_link:"https://meet.google.com/vjo-fxer-rhx",
          description:"this is a description"
        };
        let localStorageevents = JSON.parse(localStorage.getItem('savedEvents'));
        localStorage.setItem('savedEvents',JSON.stringify([...localStorageevents,currEvent]));
        this.context.dispatchCalEvent({ type: "push", payload: currEvent });
      },
      // eventDeleteHandling: "Update",
      onEventClick: async args => {
        // const event = args.e.data;
        // if (event.meeting_link) {
        //   window.open(event.meeting_link, '_blank');
        // }
        const event = args.e.data;
        this.context.setSelectedEvent(event);
      },
      onBeforeEventRender: async args => {
        console.log(args)
        const event = args.data;
        const newText = `${event.text} [${event.start.value.slice(11, 16)} - ${event.end.value.slice(11, 16)}] ${event.description}`;
        args.data.text = ''; 
        args.data.text  = newText;
      }
    };
  }

  get calendar() {
    return this.calendarRef.current.control;
  }
  // handleModalClose = () =>{
  //   this.setState({ selectedEvent: null });
  // }
  componentDidMount() {
    const year = this.context.daySelected.$d.getFullYear();
    const month = String(this.context.daySelected.$d.getMonth() + 1).padStart(2, "0");
    const day = String(this.context.daySelected.$D).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    const startDate = dateString;
    let localStorageevents = JSON.parse(localStorage.getItem('savedEvents'))
    this.calendar.update({startDate, events:localStorageevents});
  }

  componentDidUpdate(){
    const year = this.context.daySelected.$d.getFullYear();
    const month = String(this.context.daySelected.$d.getMonth() + 1).padStart(2, "0");
    const day = String(this.context.daySelected.$D).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    const startDate = dateString;
    let localStorageevents = JSON.parse(localStorage.getItem('savedEvents'))
    this.calendar.update({startDate, events:localStorageevents});
  }
  render() {
    return (
      <div style={styles.wrap}>
        {/* TODO:DELTE THE DIV AND CORRECT THE CSS */}
        <div>
          {/* <DayPilotNavigator
            selectMode={"week"}
            showMonths={3}
            skipMonths={3}
            startDate={"2023-03-07"}
            selectionDay={"2023-03-07"}
            onTimeRangeSelected={ args => {
              this.calendar.update({
                startDate: args.day
              });
            }}
          /> */}
        </div>
        <div style={styles.main}>
          <DayPilotCalendar
            {...this.state}
            ref={this.calendarRef}
            headerDateFormat="dddd, d"
            headerHeight={50}
            allowEventDelete={false}
          />
          
          {/* {this.context.selectedEvent && (
            <EventDetailModal event={this.context.selectedEvent} />
        )} */}
        </div>
      </div>
    );
  }
}

export default WeeklyCalendar;
