import React, { useState } from "react";
import "./MarkSheet.css";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

const MarkSheetRow = ({ data, idx }) => {
  console.log("🚀 ~ file: MarksheetRow.jsx:7 ~ MarkSheetRow ~ data:", data[0]);
  const dispatch = useDispatch();
  const marksheet = useSelector((state) => state.marksheet);

  const [attempted, setAttempted] = useState(false);
  const [questionMarks, setQuestionMarks] = useState(0);
  const changeAttempted = () => {
    setAttempted(!attempted);
  };

  const handleMarks = (e) => {
    setQuestionMarks(e.target.value);
    dispatch({
      type: "ADD_MARKSHEET",
      payload: {
        questionId: data[0].id,
        attempted: true,
        marks: e.target.value,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: "ADD_MARKSHEET",
      payload: { questionId: data[0].id, attempted: false, marks: 0 },
    });
  }, []);


  console.log("data",data);
  console.log("data?.tests",data?.tests);
  console.log("data?.tests?.[0]?.test_question_mapping",data?.tests?.[0]?.test_question_mapping);

  console.log("data?.tests?.[0]?.test_question_mapping?.marks",data?.tests?.[0]?.test_question_mapping?.marks);

  return (
    <tr className="bg-white">
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
      >
        {idx + 1}
      </th>
      <td className="px-6 py-4">
        <input type="checkbox" onChange={changeAttempted} />
      </td>
      <td className="px-6 py-4 flex flex-row items-center">
        {attempted ? (
          <span>
            <span className="h-[7px] w-[7px] inline-block bg-[#66CCA7] rounded-md mr-1"></span>{" "}
            Attemped
          </span>
        ) : (
          <span>
            <span className="h-[7px] w-[7px] inline-block bg-[#D6B65C] rounded-md mr-1"></span>{" "}
            Not Attemped
          </span>
        )}
      </td>
      <td className="px-6 py-4">
        {data?.[0]?.tests?.[0]?.test_question_mapping?.marks || "N.A."}
      </td>
      <td className="px-6 py-4">
        <div className="inline border border-black">
          <input
            type="text"
            value={questionMarks}
            disabled={!attempted}
            onChange={(e) => handleMarks(e)}
          />
        </div>
      </td>
    </tr>
  );
};

export default MarkSheetRow;
