import React, { useState, useContext, useEffect } from "react";
import "./MainCalendar.css";
import { getMonth } from "./util";
import CalendarHeader from "./CalendarHeader";
import Sidebar from "./Sidebar";
import Month from "./Month";
import GlobalContext from "../context/GlobalContext";
import EventDetailModal from "./EventDetailModal";
import WeeklyCalendar from "./WeeklyCalendar";
function MainCalendar() {
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal, showMonthlyCalendar, selectedEvent } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  return (
    <React.Fragment>
      {selectedEvent && (
            <EventDetailModal />
      )}

      <div className="h-screen flex flex-col">
        <CalendarHeader />
        <div className="flex flex-1">
          <Sidebar />
          {showMonthlyCalendar ? <Month month={currenMonth} /> : <WeeklyCalendar />}
        </div>
      </div>
    </React.Fragment>
  );
}

export default MainCalendar;
