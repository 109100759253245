import React, { useEffect, useRef, useState } from "react";
import {
  CgDebug,
  CgChevronLeft,
  CgChevronRight,
  CgArrowsExpandDownRight,
} from "react-icons/cg";
import { BsFillPenFill } from "react-icons/bs";
import { RiImageEditFill } from "react-icons/ri";
import AnnotatablePage from "./AnnotatablePage";
import { extendTarget } from "../PDFAnnotation";
import { useDispatch, useSelector } from "react-redux";
import Slider from "../Slider";
import PenFunction from "../PenFunction";
import MarkSheet from "../../Marksheet/MarkSheet";
import Feedback from "../../Feedback/Feedback";
import * as PDFJS from "pdfjs-dist/legacy/build/pdf";

const PaginatedViewer = (props) => {
  const [first, setfirst] = useState(false);
  const p = useSelector((state) => state.current_page);
  const total_p = useSelector((state) => state.total_pages);
  const paintToggle = useSelector((state) => state.paintToggle);
  const canvasAllPage = useSelector((state) => state.canvasAllPage);
  const current_page = useSelector((state) => state.current_page);
  

  const [page, setPage] = useState();

  const [debug, setDebug] = useState(false);

  const [annotationMode, setAnnotationMode] = useState("ANNOTATION");

  const dispatch = useDispatch();

  const containerEl = useRef();

  // Render first page on mounts
  useEffect(() => {
    // props.pdf.getPage(p).then(setPage);

    dispatch({ type: "SET_TOTAL_PAGES", payload: props.pdf.numPages });
  }, [p]);

  console.log(
    "useSelector((state) => state.current_page);",
    useSelector((state) => {
      console.log("state", state);
    })
  );

  useEffect(() => {
    const renderPDF = async () => {
      try {
        for (let index = 1; index <= props.pdf.numPages; index++) {
          // Get the current page using await
          const page = await props.pdf.getPage(index);

          const scale = props.scale || 1.5;
          console.log("scale", scale);
          const viewport = page.getViewport({ scale });

          const canvas = document.createElement("canvas");

          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Append canvas to the DOM
          containerEl.current.appendChild(canvas);

          const renderContext = {
            canvasContext: canvas.getContext("2d"),
            viewport,
          };

          // Render the page to the canvas
          await page.render(renderContext).promise;

          // Get the text content for the page
          const textContent = await page.getTextContent();

          console.log("textContent", textContent);

          // Render the text layer on top of the canvas
          await PDFJS.renderTextLayer({
            textContent: textContent,
            container: containerEl?.current?.querySelector(".textLayer1"),
            viewport: viewport,
            textDivs: [],
          }).promise;

          // Convert canvas to image data
          let imageData = canvas.toDataURL("image/png", 1.0);

          // Dispatch the image data
          dispatch({
            type: "ADD_CANVAS",
            payload: { pageNum: index, image: imageData },
          });

          // Check if it's the last page and set state
          if (index === props.pdf.numPages) {
            setfirst(true);
          }

          if (index === 1) {
            setPage(imageData);
          }
        }
      } catch (error) {
        console.log("aaaaa error", error);
      }
    };

    // Call the async function
    renderPDF();
  }, []);

  const onPreviousPage = () => {
    // const { pageNumber } = page;
    // const prevNum = Math.max(0, pageNumber - 1);
    // if (prevNum !== pageNumber)
    //   props.pdf.getPage(prevNum).then((page) => {
    //     setPage(page);
    //     dispatch({ type: "CHANGE_PAGE_NUM", payload: page });
    //   });

    console.log("************onPreviousPage************");

    console.log("current_page",current_page);
    

    if (current_page > 1) {
      console.log("canvasAllPage[current_page - 1]",canvasAllPage[current_page - 1]);
      setPage(canvasAllPage[current_page - 1]);
      dispatch({ type: "CHANGE_PAGE_NUM", payload: current_page - 1 });
    }
  };

  const onNextPage = () => {
    // const { numPages } = props.pdf;
    // const { pageNumber } = page;
    // const nextNum = Math.min(pageNumber + 1, numPages);
    // if (nextNum !== pageNumber)
    //   props.pdf.getPage(nextNum).then((page) => {
    //     setPage(page);
    //     dispatch({ type: "CHANGE_PAGE_NUM", payload: page });
    //   });

    console.log("************onNextPage************");

    console.log("current_page",current_page);

    if (current_page < props.pdf.numPages) {
      console.log("canvasAllPage[current_page + 1]",canvasAllPage[current_page + 1]);

      setPage(canvasAllPage[current_page + 1]);
      dispatch({ type: "CHANGE_PAGE_NUM", payload: current_page + 1 });
    }
  };

  const onToggleRelationsMode = () => {
    dispatch({ type: "PAINT_TOGGLE", payload: false });
    if (annotationMode === "RELATIONS") setAnnotationMode("ANNOTATION");
    else setAnnotationMode("RELATIONS");
  };

  // TODO:PEN and annotation working concurrently check into it.
  const onTogglePaintMode = () => {
    dispatch({ type: "PAINT_TOGGLE", payload: !paintToggle });
    // dispatch({type:"PAINT_MODE", payload:!paintToggle});
    if (annotationMode === "PEN") {
      setAnnotationMode("ANNOTATION");
    } else {
      setAnnotationMode("PEN");
      dispatch({ type: "PAINT_MODE", payload: !paintToggle });
    }
  };

  const onToggleImageMode = () => {
    dispatch({ type: "PAINT_TOGGLE", payload: false });
    if (annotationMode === "IMAGE") setAnnotationMode("ANNOTATION");
    else setAnnotationMode("IMAGE");
  };

  const onCreateAnnotation = (a) => {
    const extended = extendTarget(a, props.url, page.pageNumber);
    props.onCreateAnnotation && props.onCreateAnnotation(extended);
  };

  const onUpdateAnnotation = (a, p) => {
    const updated = extendTarget(a, props.url, page.pageNumber);
    const previous = extendTarget(p, props.url, page.pageNumber);
    props.onUpdateAnnotation && props.onUpdateAnnotation(updated, previous);
  };

  const onDeleteAnnotation = (a) => {
    const extended = extendTarget(a, props.url, page.pageNumber);
    props.onDeleteAnnotation && props.onDeleteAnnotation(extended);
  };

  return (
    <div>
      <div
        style={{ position: "relative", overflow: "hidden" }}
        // className='bg-[#1c2537]'
      >
        <header
          style={{ position: "absolute" }}
          // className='bg-[#1c2537]'
        >
          {/* TODO:DELETE THEM AT THE END */}
          {/* <button onClick={() => setDebug(!debug)}>
          <span className="inner">
            <CgDebug />
          </span>
        </button> */}

          <button onClick={onPreviousPage}>
            <span className="inner">
              <CgChevronLeft />
            </span>
          </button>

          <label>
            {current_page} / {total_p}
          </label>

          <button onClick={onNextPage}>
            <span className="inner">
              <CgChevronRight />
            </span>
          </button>

          {/* <button 
          className={annotationMode === 'RELATIONS' ? 'active' : null} 
          onClick={onToggleRelationsMode}>
          <span className="inner">
            <CgArrowsExpandDownRight />
          </span>
        </button> */}

          <button
            className={annotationMode === "IMAGE" ? "active" : null}
            onClick={onToggleImageMode}
          >
            <span className="inner">
              <RiImageEditFill />
            </span>
          </button>

          <button
            className={paintToggle ? "active" : null}
            onClick={onTogglePaintMode}
          >
            <span className="inner">
              <BsFillPenFill />
            </span>
          </button>

          {/* {props?.selected_row?.status === "AI_CHECK" && (
            <span onClick={props?.handleSwitchAI}>
              {" "}
              Switch to {props?.selectPdfType !== "AI" ? "AI" : "normal"} Copy
            </span>
          )} */}

          {annotationMode === "IMAGE" && <p>Add \n For Next Line in comment</p>}
        </header>

        {paintToggle && <PenFunction />}

        {first && (
          <main>
            <div className="pdf-viewer-container">
              <AnnotatablePage
                page={page}
                annotations={
                  page ? props.store.getAnnotations(page.pageNumber) : []
                }
                config={props.config}
                debug={debug}
                annotationMode={annotationMode}
                onCreateAnnotation={onCreateAnnotation}
                onUpdateAnnotation={onUpdateAnnotation}
                onDeleteAnnotation={onDeleteAnnotation}
                onCancelSelected={props.onCancelSelected}
              />
            </div>
          </main>
        )}
        <div
          style={{
            display: "none",
          }}
          ref={containerEl}
          // className={props.debug ? "page-container debug" : "page-container"}
        >
          <div className="textLayer1" />
        </div>

        {/* {<Slider />} */}
        <MarkSheet />
        <Feedback />
      </div>
    </div>
  );
};

export default PaginatedViewer;
