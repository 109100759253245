import React, { useState } from "react";

const SubAccordion = ({ data, title }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? "-" : "+"}</div>
      </div>
      {isActive && (
        <div className="accordion-content">
          <div style={{filter:"invert(0.5)",mixBlendMode:"plus-lighter"}} dangerouslySetInnerHTML={{ __html: data }} />
        </div>
      )}
    </div>
  );
};

export default SubAccordion;
