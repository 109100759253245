import React, { useState, useEffect } from "react";
import Accordion from "./Accordian";
import "./Question.css";
import ConfirmationModal from "./ConfirmationModal";
import {
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";
import uuid from "react-uuid";
import { useSelector, useDispatch } from "react-redux";
import jsPDF from "jspdf";
import { Client } from "../Client";
import AIConfirmationModal from "./AIConfirmationModal";

const Question = (props) => {
  const { selectPdfType } = props;
  const [showModal, setShowModal] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [expandQuestion, setExpandQuestion] = useState(false);

  const total_pages = useSelector((state) => state.total_pages);
  const questions = useSelector((state) => state.questions);
  const canvasAllPage = useSelector((state) => state.canvasAllPage);
  const annotations = useSelector((state) => state.annotations);
  const feedback = useSelector((state) => state.feedback);
  const marksheet = useSelector((state) => state.marksheet);
  const canvasWidth = useSelector((state) => state.canvasWidth);
  const canvasHeight = useSelector((state) => state.canvasHeight);
  const userId = localStorage.getItem("selected_userId");
  const testId = localStorage.getItem("selected_testId");
  const answerId = localStorage.getItem("selected_answerId");
  let pdfUrl;
  let textColor = [
    [255, 0, 0],
    [0, 255, 0],
    [0, 0, 255],
    [255, 255, 0],
    [255, 0, 255],
    [0, 255, 255],
    [120, 120, 0],
    [120, 0, 120],
    [0, 120, 120],
    [120, 0, 0],
    [0, 120, 0],
    [0, 0, 120],
  ];

  console.log("canvasAllPage",canvasAllPage)

  const ShowModalFunction = () => {
    if (Object.keys(feedback).length === 0) {
      console.error("Please give a feedback");
    } else {
      setShowModal(!showModal);
    }
  };
  const handleEvaluatedToggle = () => {
    createAndModifyCanvas();
  };

  console.log(questions);

  const createAndModifyCanvas = async () => {
    // Create a new jspdf instance
    const doc = new jsPDF({ unit: "px", format: [canvasWidth, canvasHeight] });

    for (let i = 0; i < total_pages; i++) {
      const filteredAnnotations = annotations.filter(
        (annotation) => annotation.target.selector.page === i + 1
      );

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      if (canvasAllPage[i + 1]) {
        doc.addImage(
          canvasAllPage[i + 1],
          "JPEG",
          0,
          0,
          canvasWidth,
          canvasHeight
        );

        for (let j = 0; j < filteredAnnotations.length; j++) {
          console.log(filteredAnnotations[j]?.target?.selector?.value);
          const [x, y, w, h] = filteredAnnotations[j]?.target?.selector?.value
            .split(":")[1]
            .split(",");
          console.log(x, y, w, h);
          let intx = parseInt(x);
          let inty = parseInt(y);
          let intw = parseInt(w);
          let inth = parseInt(h);
          let selectedColor = textColor[j];
          doc.setDrawColor(
            selectedColor[0],
            selectedColor[1],
            selectedColor[2]
          );
          doc.rect(intx, inty, intw, inth, "S");
          // doc.addImage(canvas, "PNG", 400,289,117,88);
          // doc.rect(x,y,w,h);
        }
        // if (filteredAnnotations.length > 0) doc.addPage();
        console.log(filteredAnnotations);
        for (let j = 0; j < filteredAnnotations.length; j++) {
          const x = 10;
          const y =
            parseInt(canvasHeight * 0.05) + j * parseInt(canvasHeight * 0.05);
          let selectedColor = textColor[j];
          doc.setFontSize(parseInt(canvasHeight * 0.025));
          doc.setTextColor(
            selectedColor[0],
            selectedColor[1],
            selectedColor[2]
          );
          doc.text(filteredAnnotations[j]?.body[0]?.value, x, y);
        }
      }

      if (i != total_pages - 1) {
        doc.addPage();
      }
    }
    // doc.save("canvas-example.pdf");
    const file = await doc.output("blob");
    const pdfsentresponse = await gelFileUrl(file);
    pdfUrl = pdfsentresponse.url;

    const body = {
      evaluated_pdf: localStorage.getItem("selected_pdf"),
      userId: userId,
      testId: testId,
      canvas: canvasAllPage,
      annotations: annotations,
      marksheet: marksheet,
      feedback: feedback,
      final_pdf: pdfUrl,
    };

    let res = await Client.put(`/updatereviewrcopy?answerId=${answerId}`, body);

    console.log(res);
    window.location.href = "/";
  };

  const gelFileUrl = async (file) => {
    console.log(
      "🚀 ~ file: Client.js:158 ~ const_uploadmediaAndReturn= ~ file:",
      file
    );
    const data = new FormData();

    data.append("files", file);

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    try {
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "image/gif" ||
        file.type == "application/pdf" ||
        file.type == "application/vnd.oasis.opendocument.spreadsheet" ||
        file.type == "video/mp4" ||
        file.type == "image/webp" ||
        file.type == "video/mpeg"
      ) {
        let res = await Client.post(`/uploadfile`, data, options);
        console.log("🚀 ~ file: Client.js:128 ~ .then ~ res:", res.data);
        if (res.status == 200) {
          console.log(res.data);
          return res.data;
        }
      } else {
      }
    } catch (err) {
      console.log("🚀 ~ file: Client.js:136 ~ const_uploadmedia= ~ err:", err);
    }
  };

  return (
    <div
      className={
        expandQuestion ? "main__Question_expand" : "main__Question_collapsed"
      }
    >
      <div className="header__Question bg-white flex justify-end">
        <button
          className="sticky bg-[#C9F7F5] text-base rounded-md text-[#1BC5BD] py-2 px-5"
          onClick={ShowModalFunction}
        >
          Submit
        </button>
      </div>
      <div
        className="questionButton"
        onClick={() => {
          setExpandQuestion(!expandQuestion);
        }}
      >
        {expandQuestion ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
      </div>
      <div className="accordion">
        {questions.map((data, index) => (
          <Accordion key={data[0].id} data={data} index={index} />
        ))}
      </div>
      {showModal && selectPdfType == "normal" && (
        <ConfirmationModal
          handleEvaluatedToggle={handleEvaluatedToggle}
          ShowModalFunction={ShowModalFunction}
        />
      )}

      {showModal && selectPdfType == "AI" && (
        <AIConfirmationModal
          handleEvaluatedToggle={handleEvaluatedToggle}
          ShowModalFunction={ShowModalFunction}
        />
      )}
    </div>
  );
};

export default Question;
