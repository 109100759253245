import React from "react";
import { Link } from "react-router-dom";
import { WomanSVG } from "../assets/WomanSVG";
import { ManSVG } from "../assets/ManSVG";
import { useSelector, useDispatch } from "react-redux";

const EvaluationRow = ({ data }) => {


  const dispatch = useDispatch();

  const handleEvaluateButton = (pdfName, userId, testId, id,qid,data) => {
    // pdfName = "sample.pdf";
    console.log("pdfName",pdfName)
    localStorage.setItem("selected_pdf", pdfName);
    localStorage.setItem("selected_userId", userId);
    localStorage.setItem("selected_testId", testId);
    localStorage.setItem("selected_answerId", id);
    localStorage.setItem("quesId", qid);

    localStorage.setItem("selected_row", JSON.stringify(data));
  };

  

  function getTime(date) {
    return new Date(date).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  function getDate(date) {
    return new Date(date).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }

  return (
    <>
      <tr className="bg-white border-b">
        <th
          scope="row"
          className="px-6 py-4 font-font-semibold text-[#464E5F] whitespace-nowrap p-1 flex flex-row items-center"
        >
          {<ManSVG />}
          <div className="flex flex-col">
            {data.user.firstName} {data.user.lastName}
            <span className="text-[#B5B5C3] text-sm">{data.exam}</span>
          </div>
        </th>
        <td className="px-6 py-4 font-semibold text-[#464E5F]">
          {data.Subject}
        </td>
        <td className="px-6 py-4 font-semibold text-[#464E5F]">
          <div className="flex flex-col">
            {getDate(data.createdAt)}
            <span className="text-[#B5B5C3] text-sm">
              {getTime(data.createdAt)}
            </span>
          </div>
        </td>
        <td className="px-6 py-4 font-semibold text-[#464E5F]">
          <div className="flex flex-col">
            {data.assigned_date}
            <span className="text-[#B5B5C3] text-sm">{data.assigned_time}</span>
          </div>
        </td>
        {/* <td className="px-5 py-4">
                <div className='flex flex-col'>{data.evaludated_date}<span className='text-[#B5B5C3] text-sm'>{data.evaluated_time}</span></div>
            </td> */}
        <td className="px-6 py-4 font-semibold">
          <span
            className={
              data.status === "Pending"
                ? "text-[#ADA7FF]"
                : data.status === "Delayed"
                  ? "text-[#FF0000]"
                  : "text-[#1BC5BD]"
            }
          >
            {data.status}
          </span>
        </td>
        <td className="px-6 py-4 font-semibold">
          <Link to="/evaluate">
            <button
              className="p-2 bg-[#C9F7F5] text-[#1BC5BD] rounded-md"
              onClick={() => {
                handleEvaluateButton(data.useranswerInfo[0].useranswerInfo, data.userId, data.testId, data.id,data?.questionId,data);
                // addQuestiontToReducer(data.test.questions);
              }}
            >
              Evaluate
            </button>
          </Link>
        </td>
      </tr>
    </>
  );
};

export default EvaluationRow;
