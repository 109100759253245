//import useState hook to create menu collapse state
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './MenuC.css';
import authServices from "../services/auth-services";
//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

//import icons from react icons
import { FaHistory } from "react-icons/fa";
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { IoNewspaperOutline } from 'react-icons/io5'
import { GiPapers } from 'react-icons/gi'
import { IoIosPaper } from 'react-icons/io'
import { CgProfile } from 'react-icons/cg';
import logo from '../assets/small_logo.png';
import "react-pro-sidebar/dist/css/styles.css";



const MenuC = () => {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [username, setUsername] = useState('');
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);


  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const handleLogout = () => {
    authServices.logout();
    window.location.href = '/login';
  }
  return (
    <>
      <div id="header" onMouseEnter={menuIconClick} onMouseLeave={menuIconClick}
        style={{ position: "absolute" }}>
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext" style={{ display: 'flex', }}>
              <img src={logo} height={50} width={50} />
              {!menuCollapse && <p>AI Koach</p>}
            </div>
            {/* <div className="closemenu" onClick={menuIconClick}>
              {menuCollapse ? (
                <FiArrowRightCircle />
              ) : (
                <FiArrowLeftCircle />
              )}
            </div> */}
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square"
              menuItemStyles={{
              }}>
              <MenuItem icon={<FiHome />}>Dashboard</MenuItem>
              <hr className='bg-[#6d6aff] mx-2 h-[1px]' />
              <MenuItem className={activeLink === '/' ? 'active' : ''} icon={<IoIosPaper />}><Link to="/">Evaluation</Link></MenuItem>
              <MenuItem className={activeLink === '/bills' ? 'active' : ''} icon={<IoNewspaperOutline />}><Link to="/bills" >Bills</Link></MenuItem>
              {/* <MenuItem icon={<HiOutlineDocumentReport />}><Link to="addnewpdf">Add New PDF</Link></MenuItem> */}
              <MenuItem className={activeLink === '/calendar' ? 'active' : ''} icon={<FaHistory />}><Link to="/calendar">Meeting Calendar</Link></MenuItem>
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem icon={<CgProfile />}>{authServices.getCurrentUser().username}</MenuItem>
              <MenuItem icon={<FiLogOut />} onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default MenuC