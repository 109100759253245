import React from "react";
import axios from "axios";

const REACT_APP_API_URL =
  process.env.REACT_APP_API_URL || "https://65.2.25.127.nip.io/api/v1";

export const Client = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 100000,
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});
