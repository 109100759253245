import React, { useState } from "react";
import "./Feedback.css";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FeedbackOption from "./FeedbackOption";
import uuid from "react-uuid";
const Feedback = () => {
  const [up, setUp] = useState(false);
  const [error, setError] = useState(null);
  const [contextUnderstanding, setContextUnderstanding] = useState(null);
  const [knowledgeProficiency, setKnowledgeProficiency] = useState(null);
  const [languageProficiency, setLanguageProficiency] = useState(null);
  const [introductionCapability, setIntroductionCapability] = useState(null);
  const [structureAndPresentationProficiency, setStructureAndPresentationProficiency] = useState(null);
  const [concludingCapability, setConcludingCapability] = useState(null);
  
  const handleChange = () => {
    setUp(!up);
  };
  const dispatch = useDispatch();

  const submitFeedback = () => {
    if(contextUnderstanding && knowledgeProficiency && languageProficiency && introductionCapability && structureAndPresentationProficiency && concludingCapability){
      const feedback={
        contextUnderstanding,
        knowledgeProficiency,
        languageProficiency,
        introductionCapability,
        structureAndPresentationProficiency,
        concludingCapability
      }
      dispatch({ type: "ADD_FEEDBACK", payload: feedback });

      setUp(!up);
    }else{
      setError("Please fill all the values");
      console.error("Please fill all the values");
    }
  };

  const handleContextUnderstanding = (event) => {
    setContextUnderstanding(event.target.value);
    console.log("handleContextUnderstanding",event.target.value);
  };

  const handleKnowledgeProficiency = (event) => {
    setKnowledgeProficiency(event.target.value);
    console.log("handleKnowledgeProficiency",event.target.value);
  };

  const handleLanguageProficiency = (event) => {
    setLanguageProficiency(event.target.value);
    console.log("handleLanguageProficiency",event.target.value);
  };

  const handleIntroductionCapability = (event) => {
    setIntroductionCapability(event.target.value);
    console.log("handleIntroductionCapability",event.target.value);
  };
  const handleStructureAndPresentationProficiency = (event) => {
    setStructureAndPresentationProficiency(event.target.value);
    console.log("handleStructureAndPresentationProficiency",event.target.value);
  };
  const handleConcludingCapability = (event) => {
    setConcludingCapability(event.target.value);
    console.log("handleConcludingCapability",event.target.value);
  };

  return (
    <div
      className={
        !up
          ? "backward absolute left-0 right-0 z-30"
          : "forward bottom-[-352px] absolute left-0 right-0 z-30"
      }
    >
      <button
        className={up ? "bg-[#6FCF97] feedback" : "feedback"}
        onClick={handleChange}
      >
        <div className="flex flex-row items-center">
          Feedback
          {up ? (
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.6653 6.0636L8.33195 11.3969L2.99862 6.0636"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.00757 11.6475L8.34974 6.32298L13.6742 11.6652"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </button>
      <div className={"left-0 right-0 z-30"}>
        <div className="relative overflow-x-auto bg-[#EAE2FF] p-[20px]">
          <div className="flex flex-col w-[80%] m-auto">
              <FeedbackOption
                uuid='contextUnderstanding'
                point="A) Contextual Understanding :"
                tooltipTitle='Comprehensively grasping the main demand or theme of the question by gaining a thorough understanding of the contextual framework in which the question is presented. Streamlining the response by strategically prioritizing and focusing on the "Key words" and "Tail words" used in the question, such as Elucidate, Explain, Comment, Examine, critically examine, Discuss, Analyze, Illustrate, Review, Argue, Justify, and other similar cues.'
                value={contextUnderstanding}
                handleChange={handleContextUnderstanding}
              />
              <FeedbackOption
                uuid='knowledgeProficiency'
                point="B) Knowledge Proficiency for the question :"
                tooltipTitle='Crafting a response that aligns with the contextual understanding of the question, supported by relevant examples, facts, data, arguments, critical analysis, and other pertinent evidence.'
                value={knowledgeProficiency}
                handleChange={handleKnowledgeProficiency}
              />
              <FeedbackOption
                uuid='languageProficiency'
                point="C) Language Proficiency (ELPS Score):"
                tooltipTitle='i) Arranging the content in coherent sentences and using simple language to express ideas effectively.\n
                ii) Ensuring grammatical accuracy.\n
                iii) Utilizing technical terms related to the subject judiciously to adhere to word limits and complete the question within the given timeframe.'
                value={languageProficiency}
                handleChange={handleLanguageProficiency}
              />
              <FeedbackOption
                uuid='introductionCapability'
                point="D) Introduction Capability:"
                tooltipTitle='An effective and pertinent start is necessary to give a jumpstart to the response. This could be achieved by providing relevant background information, data, current events, or other related contextual details.'
                value={introductionCapability}
                handleChange={handleIntroductionCapability}
              />
              <FeedbackOption
                uuid='structureAndPresentationProficiency'
                point="E) Structure & Presentation Proficiency:"
                tooltipTitle='i) Structuring the content in line with the various components of the question to sustain coherence and fluidity in the response.\n
                ii) Employing headings and subheadings, bullet points, flowcharts, diagrams, and other relevant visual aids to present the information in a clear and effective manner.'
                value={structureAndPresentationProficiency}
                handleChange={handleStructureAndPresentationProficiency}
              />
              <FeedbackOption
                uuid='concludingCapability'
                point="F) Concluding Capability:"
                tooltipTitle='Concluding the response with closing remarks that offer suggestions for a way forward, innovative solutions, and a balanced incorporation of diverse perspectives.'
                value={concludingCapability}
                handleChange={handleConcludingCapability}
              />
              <button className="bg-[#C9F7F5] text-base rounded-md text-[#1BC5BD] py-2 px-5" onClick={submitFeedback}>
                  Submit Feedback
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
