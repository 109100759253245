import React,{useContext} from 'react'
import GlobalContext from "../context/GlobalContext";

const EventDetailModal = () => {

    const { setSelectedEvent, selectedEvent  } = useContext(GlobalContext);
    return (
        <div id="popup-modal" tabIndex="-1" className="flex items-center justify-center modalCSS fixed z-[100] p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full">
            <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow p-5">
                    <h2 className="m-4 text-xl font-medium text-black text-center">{selectedEvent.text}</h2>
                    <div className="p-6 text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500"><span className='text-black'>MEETING DATE:</span> {new Date(selectedEvent.end).toISOString().slice(0, 10)}</h3><br />
                        <h3 className="mb-5 text-lg font-normal text-gray-500"><span className='text-black'>MEETING TIME: </span>{selectedEvent.start.value ? selectedEvent.start.value.slice(11,16) : selectedEvent?.start?.slice(11, 16)} - {selectedEvent.end.value ? selectedEvent.end.value.slice(11,16) : selectedEvent?.end?.slice(11, 16)}</h3><br />

                        <h3 className="mb-5 text-lg font-normal text-gray-500">{selectedEvent.description}</h3>
                        <a href={selectedEvent.meeting_link} target="_blank">
                        <button data-modal-hide="popup-modal" type="button" className="text-white bg-[#6D6AFF] focus:ring-4 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                            Go To Meeting
                        </button>
                        </a>
                        <button data-modal-hide="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10" onClick={() => setSelectedEvent(null)}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventDetailModal