import React, { useEffect, useRef, useState } from "react";
import * as PDFJS from "pdfjs-dist/legacy/build/pdf";
import { Recogito } from "@recogito/recogito-js/src";
import { Annotorious } from "@recogito/annotorious/src";
import { useSelector, useDispatch } from "react-redux";
import { splitByType } from "../PDFAnnotation";
const AnnotatablePage = (props) => {
  const containerEl = useRef();

  const [anno, setAnno] = useState();
  const [recogito, setRecogito] = useState();
  const [isPainting, setIsPainting] = useState(false);
  const [startPainting, setStartPainting] = useState(false);
  const [startX, setStartX] = useState();
  const [startY, setStartY] = useState();
  const [historyArr, setHistoryArr] = useState([]);
  const paintMode = useSelector((state) => state.paintMode);
  const paintColor = useSelector((state) => state.paintColor);
  const line_width = useSelector((state) => state.lineWidth);
  const paintToggle = useSelector((state) => state.paintToggle);
  const [mouseTime, setMouseTime] = useState();
  const current_page = useSelector((state) => state.current_page);

  const dispatch = useDispatch();
  const history = useSelector((state) => state.history);
  const historyIndex = useSelector((state) => state.historyIndex);
  const colors = useSelector((state) => state.colors);
  const colorIndex = useSelector((state) => state.colorIndex);

  // Cleanup previous Recogito instance, canvas + text layer
  const destroyPreviousPage = () => {
    // Clean up previous Recogito + Annotorious instance, if any
    if (recogito) recogito.destroy();

    if (anno) anno.destroy();

    let canvas = containerEl.current.querySelector("canvas");
    if (canvas) containerEl.current.removeChild(canvas);

    const textLayer = containerEl.current.querySelector(".textLayer");
    textLayer.innerHTML = "";
    dispatch({ type: "RESET_HISTORY_INDEX" });
  };

  const debounce = (func, delay) => {
    let timerId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const onCreateAnnotation = (a, page) => {
    let canvas = containerEl.current.querySelector("canvas");
    const chars = a.target?.selector?.value
      ?.split("xywh=pixel:")
      .filter((d) => d)
      .toString(",")
      .split(",");

    canvas.getContext("2d").font = "20px serif";
    canvas.getContext("2d").fillStyle = "red";

    const txt = a?.body?.[0]?.value;

    if (txt) {
      const lines = txt.split("\\n");
      const lineheight = 15;

      for (let i = 0; i < lines.length; i++) {
        canvas
          .getContext("2d")
          .fillText(lines[i], chars[0], +chars[1] + i * lineheight);
      }
    }

    // canvas.getContext("2d").fillText(a.body?.[0]?.value, chars[0], chars[1]);

    let imageData = canvas.toDataURL("image/png", 1.0);

    dispatch({ type: "ADD_TO_HISTORY", payload: imageData });
    // dispatch({ type: "HISTORY_INDEX_INCREMENT" });
    dispatch({ type: "SET_HISTORY_INDEX" });
    // let curr_page;
    // console.log("current_page._pageIndex", page._pageIndex);
    // if (page._pageIndex || page._pageIndex == 0) {
    //   curr_page = page._pageIndex + 1;
    // } else {
    //   curr_page = page;
    // }

    console.log("current_page",current_page)

    dispatch({
      type: "ADD_CANVAS",
      payload: { pageNum: current_page, image: imageData },
    });
    // props.onCreateAnnotation(a);s
  };

  function handleMouseDown(e) {
    let mouseDownTime = new Date().getTime();
    setMouseTime(mouseDownTime);
    setIsPainting(true);
    if (paintToggle && paintMode && history.length == 0) {
      let canvas = containerEl.current.querySelector("canvas");
      let imageData = canvas.toDataURL("image/png", 1.0);
      dispatch({ type: "ADD_TO_HISTORY", payload: imageData });
      dispatch({ type: "HISTORY_INDEX_INCREMENT" });
    }
    setStartX(e.clientX);
    setStartY(e.clientY);
  }

  function handleMouseUp(e) {
    setIsPainting(false);
    let mouseUpTime = new Date().getTime();
    let timeElapsed;
    if (mouseUpTime && mouseTime) {
      timeElapsed = mouseUpTime - mouseTime;
    }
    setMouseTime(null);
    if (paintMode && paintToggle) {
      let canvas = containerEl.current.querySelector("canvas");
      canvas.getContext("2d").stroke();
      canvas.getContext("2d").beginPath();
      if (timeElapsed > 300) {
        let imageData = canvas.toDataURL("image/png", 1.0);
        dispatch({ type: "ADD_TO_HISTORY", payload: imageData });
        dispatch({ type: "SET_HISTORY_INDEX" });
      }
    }
  }

  const handleMouseMove = (e) => {
    let canvas = containerEl.current.querySelector("canvas");
    if (history.length == 0) {
      let imageData = canvas.toDataURL("image/png", 1.0);
      dispatch({ type: "ADD_TO_HISTORY", payload: imageData });
      dispatch({ type: "SET_HISTORY_INDEX" });
      // let curr_page;
      // if (current_page._pageIndex || current_page._pageIndex == 0) {
      //   curr_page = current_page._pageIndex + 1;
      // } else {
      //   curr_page = current_page;
      // }
      // console.log("curr_page", curr_page);
      dispatch({
        type: "ADD_CANVAS",
        payload: { pageNum: current_page, image: imageData },
      });
    }

    if (paintMode && paintToggle) {
      if (!isPainting) {
        return;
      }
      canvas.getContext("2d").lineWidth = line_width;
      canvas.getContext("2d").lineCap = "round";
      canvas
        .getContext("2d")
        .lineTo(
          e.clientX - canvas.getBoundingClientRect().left,
          e.clientY - canvas.getBoundingClientRect().top
        );
      canvas.getContext("2d").stroke();
    }
  };

  // Render on page change
  useEffect(() => {
    destroyPreviousPage();

    // if (props.page) {
    //   const scale = props.scale || 1.5;
    //   const viewport = props.page.getViewport({ scale });

    //   const canvas = document.createElement("canvas");
    //   // const ctx = canvas.getContext('2d');

    //   canvas.height = viewport.height;
    //   canvas.width = viewport.width;
    //   dispatch({ type: "ADD_CANVAS_HEIGHT", payload: viewport.height });
    //   dispatch({ type: "ADD_CANVAS_WIDTH", payload: viewport.width });
    //   canvas.getContext("2d").strokeStyle = paintColor;

    //   // canvas.getContext('2d').strokeRect(10, 10, 100, 100);
    //   containerEl.current.appendChild(canvas);

    //   canvas.addEventListener("mousedown", handleMouseDown);
    //   canvas.addEventListener("mousemove", handleMouseMove);
    //   canvas.addEventListener("mouseup", handleMouseUp);
    //   const renderContext = {
    //     canvasContext: canvas.getContext("2d"),
    //     viewport,
    //   };
    //   props.page.render(renderContext);
    //   props.page.getTextContent().then((textContent) =>
    //     PDFJS.renderTextLayer({
    //       textContent: textContent,
    //       container: containerEl.current.querySelector(".textLayer"),
    //       viewport: viewport,
    //       textDivs: [],
    //     }).promise.then(() => {
    //       const config = props.config || {};

    //       const { text, image } = splitByType(props.annotations);

    //       const r = new Recogito({
    //         ...config,
    //         content: containerEl.current.querySelector(".textLayer"),
    //         mode: "pre",
    //       });

    //       // r.on("createAnnotation", (a) => {
    //       //   console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaa", canvas);

    //       //   onCreateAnnotation(a);
    //       // });
    //       // r.on("updateAnnotation", (a, p) => props.onUpdateAnnotation(a, p));
    //       // r.on("deleteAnnotation", (a) => props.onDeleteAnnotation(a));
    //       // r.on("cancelSelected", (a) => props.onCancelSelected(a));

    //       // TODO split: text annotations only
    //       r.setAnnotations(text);
    //       setRecogito(r);

    //       const anno = new Annotorious({
    //         ...config,
    //         image: canvas,
    //       });

    //       anno.on("createAnnotation", (a) => {
    //         // props.onCreateAnnotation(a);
    //         onCreateAnnotation(a, props.page);
    //       });
    //       // anno.on("updateAnnotation", (a, p) => props.onUpdateAnnotation(a, p));
    //       // anno.on("deleteAnnotation", (a) => props.onDeleteAnnotation(a));
    //       // anno.on("cancelSelected", (a) => props.onCancelSelected(a));

    //       anno.setAnnotations(image);
    //       setAnno(anno);

    //       // r.on("selectAnnotation", () => anno.selectAnnotation());
    //       // anno.on("selectAnnotation", () => r.selectAnnotation());
    //     })
    //   );
    // }

    if (props.page) {
      // Make sure to provide the base64 image data as a prop
      const scale = props.scale || 1.5;

      // Create a canvas element
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set canvas dimensions (adjust as needed)
      canvas.height = props.height || 1200; // Default height if not provided
      canvas.width = props.width || 892; // Default width if not provided

      // Dispatch the canvas height and width to your state
      dispatch({ type: "ADD_CANVAS_HEIGHT", payload: canvas.height });
      dispatch({ type: "ADD_CANVAS_WIDTH", payload: canvas.width });

      // Set stroke style for the canvas
      ctx.strokeStyle = paintColor;

      // Append the canvas to your container
      containerEl.current.appendChild(canvas);

      // Add mouse event listeners to the canvas
      canvas.addEventListener("mousedown", handleMouseDown);
      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseup", handleMouseUp);

      // Create an Image object to load the base64 image
      const img = new Image();
      img.src = props.page; // Base64 image data from props

      // Wait for the image to load and then draw it on the canvas
      img.onload = () => {
        // Draw the image on the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Continue with your annotation logic after the image is drawn
        const config = props.config || {};

        // Split annotations by type
        const { text, image } = splitByType(props.annotations);

        // Setup text annotations with Recogito
        const r = new Recogito({
          ...config,
          content: containerEl.current.querySelector(".textLayer"),
          mode: "pre",
        });

        // Set annotations for Recogito
        r.setAnnotations(text);
        setRecogito(r);

        // Setup image annotations with Annotorious
        const anno = new Annotorious({
          ...config,
          image: canvas,
        });

        anno.on("createAnnotation", (a) => {
          onCreateAnnotation(a);
        });

        // Set annotations for Annotorious
        anno.setAnnotations(image);
        setAnno(anno);
      };

      // Handle image loading errors (optional)
      img.onerror = (err) => {
        console.error("Image loading failed", err);
      };
    }
  }, [props.page]);

  useEffect(() => {
    let canvas = containerEl.current.querySelector("canvas");
    if (canvas) {
      const img = new Image();
      img.src = history[historyIndex];
      img.onload = () => {
        canvas.getContext("2d").drawImage(img, 0, 0);
      };
    }
  }, [historyIndex]);

  // useEffect(() => {
  //   let canvas = containerEl.current.querySelector("canvas");
  //   console.log(colorIndex);
  //   if (canvas) {
  //     canvas.getContext('2d').fillStyle = colors[colorIndex];
  //   }
  // }, [colorIndex]);

  useEffect(() => {
    let canvas = containerEl.current.querySelector("canvas");
    if (canvas) {
      canvas.getContext("2d").strokeStyle = paintColor;
    }
  }, [paintColor]);

  useEffect(() => {
    // Hack
    if (recogito && recogito.getAnnotations() === 0) {
      recogito.setAnnotations(props.annotations);
    }
  }, [props.annotations]);

  useEffect(() => {
    if (containerEl.current) {
      const imageLayer = containerEl.current.querySelector(
        "svg.a9s-annotationlayer"
      );

      if (imageLayer) {
        if (props.annotationMode === "IMAGE") {
          imageLayer.style.pointerEvents = "auto";
        } else {
          imageLayer.style.pointerEvents = null;
          recogito.setMode(props.annotationMode);
        }
      }
    }
  }, [props.annotationMode]);

  return (
    <div
      ref={containerEl}
      className={props.debug ? "page-container debug" : "page-container"}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={(e) => {
        handleMouseMove(e);
      }}
    >
      <div className="textLayer" />
    </div>
  );
};

export default AnnotatablePage;
